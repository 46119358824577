<style scoped>
select, input{
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #808080
}
</style>

<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

  <b-row class="d-flex flex-column align-content-around mt-2 mb-2 align-items-center">
        <!-- Search -->
          <div class="d-flex justify-content-between flex-wrap" style="width: 80%;">
            <div class="mr-2">
              <label for="month">Miesiąc:</label>
              <select v-model="month" style="width: 100%;" id="month">
                  <option v-for="mth in months">{{mth}}</option>
              </select>
            </div>
            <div class="mr-2">
              <label for="year">Rok:</label>
              <select v-model="year" style="width: 100%;" id="year">
                  <option v-for="yr in years">{{yr}}</option>
              </select>
            </div>
            <div class="mr-2 d-flex flex-column">
              <label for="client">Klient:</label>
              <input type="text" name="client" id="client" v-model="client" placeholder="adres, imię, nazwisko itd.">
            </div>
          </div>
          <div class="mt-2" style="width: 80%;" ref="serviceGroupsRef">
            <div v-for="group in serviceGroups" :key="group" class="d-inline-flex align-items-start mx-1">
              <input style="margin-right: 3px;" type="checkbox" :id="group" />
              <label :for="group">{{ group }}</label>
            </div>
          </div>
          <b-button variant="primary" class="mt-2" @click="fetchFilters">
              Szukaj
          </b-button>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych serwisów"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-client', params: { id: data.item.client_id } }" v-b-tooltip.hover title="Klient">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-realizedservice-filter', query: { id_filter: data.item.id } }" v-b-tooltip.hover title="Nowy serwis">
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-reparatur-filter', query: { id_filter: data.item.id } }" v-b-tooltip.hover title="Nowa naprawa">
              <feather-icon icon="ToolIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BFormCheckbox, BDropdown, BDropdownItem, BFormSelect, BFormSelectOption,
  BFormDatepicker,
  BFormGroup
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
    BFormGroup
  },
  data() {
    return {
      fields: [
        {
          key: 'lp',
          label: 'L.p.',
          sortable: false,
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Nazwa',
          sortable: false,
        },
        {
          key: 'service_date',
          label: 'Data serwisu',
          sortable: false,
        },
        {
          key: 'next_service_symbol',
          label: 'Kod serwisu',
          sortable: false,
        },
        {
          key: 'client.firstname',
          label: 'Imię i nazwisko klienta',
          formatter: (value, key, item) => {
              return `${item.client.firstname} ${item.client.lastname}`
          }
        },
        {
          key: 'client.address',
          label: 'Adres',
          formatter: (value, key, item) => {
              return `${item.client.city} ul. ${item.client.address}`
          }
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      items: [],
      selectedGroups: [],
      serviceGroups: [
        'VAR',
        'R-ZM',
        '1-12E',
        'COM',
        'ROH',
        'LIFE',
        'MINI COM',
        'SP-KF-12E',
        'SR-KF-12E',
        'SR-KF',
        'ADR1',
        'ADR4',
        'SP-KF-R',
        'SR-KF-R',
        'Zmiekczacz 6M'
      ],
      months: ['---','01','02','03','04','05','06','07','08','09','10','11','12'],
      month: '---',
      year: `---`,
      years: [
        '---',
        `${new Date().getFullYear()}`,
        `${new Date().getFullYear()+1}`,
        `${new Date().getFullYear()+2}`,
        `${new Date().getFullYear()+3}`,
        `${new Date().getFullYear()+4}`
      ],
      client: ''
    }
  },
  // watch:{
  //   searchActive: function (value){
  //     this.fetchFilters()
  //   },
  //   fromDate: function (value){
  //     this.fetchFilters()
  //   },
  //   toDate: function(value){
  //     this.fetchFilters()
  //   },
  //   city: function(value){
  //     this.fetchFilters()
  //   },
  //   address: function(value){
  //     this.fetchFilters()
  //   },
  //   serviceSymbol: function(value){
  //     this.fetchFilters()
  //   },
  // },
  mounted() {
    this.fetchFilters()
    this.fromDate = new Date().toISOString().slice(0, 10)
    this.toDate = new Date().toISOString().slice(0, 10)
  },
  created(){
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
    axios.get(`${process.env.VUE_APP_API_URL}Service`, config).then(response => {
	  this.services = response.data
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    fetchFilters() {
      let serviceGroups = []
      this.$refs.serviceGroupsRef.querySelectorAll('input').forEach(el => {
        if(el.checked){
          serviceGroups.push(el.id)
        }
      })
      // console.log(serviceGroups)
      const queryParams = {
        month: this.month !== '---' ? this.month : '',
        year: this.year !== '---' ? this.year : '',
        client: this.client
      }
      let serviceSymbolsStr = ''
      if(serviceGroups.length > 0){
        serviceGroups.forEach(item => {
          serviceSymbolsStr = `${serviceSymbolsStr}&next_service_symbol[]=${item}`
        })
      }
      const searchParams = new URLSearchParams(queryParams)
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}CurrentServices?${searchParams.toString()}${serviceSymbolsStr ? serviceSymbolsStr : ''}`, config).then(response => {
        this.items = response.data
        this.items.map((item, index) => {
          item.lp = index+1
        })
      }).catch(error => {
        console.log(error)
      })
    },
    deleteFilter(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}Filter/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto serwis',
          '',
          'success',
        )
        this.fetchFilters()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
